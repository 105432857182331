// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBKYL8suYjwWKgfBa72rdp2WJtuh6xuLBo',
    authDomain: 'mn-election-night-api.firebaseapp.com',
    databaseURL: 'https://mn-election-night-api.firebaseio.com',
    projectId: 'mn-election-night-api',
    storageBucket: 'mn-election-night-api.appspot.com',
    messagingSenderId: '663841966996',
    appId: '1:663841966996:web:5a02fb4749b3153d9f9d81',
    measurementId: 'G-B1H2RCETX2',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
