<mat-toolbar color="primary">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="toggleSettings()"
  >
    <mat-icon aria-label="Side nav toggle icon">settings</mat-icon>
  </button>
  <span class="title">MN Election Night 2022</span>
</mat-toolbar>
