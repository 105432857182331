<div
  class="grid-container settings"
  [ngClass]="{ visible: (displaySettings | async) }"
>
  <mat-card>
    <mat-card-header>
      <mat-card-title>About</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Updating every 60 seconds with
        <a
          href="https://electionresults.sos.state.mn.us/Select/MediaFiles/Index?ersElectionId=136"
          target="_blank"
          >data</a
        >
        from the Minnesota Secretary of State.
      </p>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="!isMobile">
    <mat-card-header>
      <mat-card-title>Slideshow</mat-card-title>
      <mat-card-subtitle
        >Scrolls horizontally to next "slide" every 20
        seconds</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content>
      <button
        mat-flat-button
        [color]="(slideshow | async) ? 'primary' : ''"
        (click)="toggleSlideshow()"
      >
        Slideshow
      </button>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Contests</mat-card-title>
      <mat-card-subtitle>Choose which contests to display</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let set of contestSets | async | keyvalue">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ set.key | contestSet }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <button
              mat-flat-button
              (click)="toggleSet(set, true)"
              *ngIf="set.key !== 'A' && set.key !== 'B' && set.key !== 'C' && set.key !== 'D' && set.key !== 'E' && set.key !== 'F'"
            >
              Show All
            </button>
            <button
              mat-flat-button
              (click)="toggleSet(set, false)"
              *ngIf="set.key !== 'A' && set.key !== 'B' && set.key !== 'C' && set.key !== 'D' && set.key !== 'E' && set.key !== 'F'"
            >
              Hide All
            </button>
            <button
              mat-flat-button
              (click)="toggleContest(set.key, contest.key)"
              [color]="hidden[set.key + contest.key] ? '' : 'primary'"
              *ngFor="let contest of set.value | keyvalue"
            >
              {{ contest.value.officeName }}
            </button>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</div>

<div
  class="grid-container results"
  [ngClass]="{
    slideshow: (slideshow | async),
    hidden: (displaySettings | async)
  }"
  #contestContainer
>
  <ng-container *ngFor="let set of contestSets | async | keyvalue">

    <ng-container *ngFor="let contest of set.value | keyvalue">
      <mat-card
        *ngIf="contest.value.officeName && !hidden[set.key + contest.key]"
      >
        <mat-card-header>
          <mat-card-title>{{ contest.value.officeName }}</mat-card-title>
          <mat-card-subtitle
            class="clickable"
            (click)="precinctPercentage = !precinctPercentage"
            >{{
              precinctPercentage
                ? ((contest.value.precinctsReporting /
                    contest.value.totalPrecincts) *
                    100 | number: '1.0-1') + '% of'
                : contest.value.precinctsReporting +
                  ' of ' +
                  contest.value.totalPrecincts
            }}
            Precincts Reporting</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <mat-list>
            <ng-container
              *ngFor="let choice of contest.value.choicesSorted; last as isLast"
            >
              <mat-list-item
                *ngIf="!isLast || choice.partyAbbreviation !== 'WI'"
                class="clickable"
                (click)="votePercentage = !votePercentage"
                [matBadge]="
                  choice.partyAbbreviation &&
                  choice.partyAbbreviation !== 'WI' &&
                  choice.partyAbbreviation !== 'NP'
                    ? choice.partyAbbreviation[0]
                    : ''
                "
                matBadgePosition="before"
                [ngClass]="
                  choice.partyAbbreviation ? choice.partyAbbreviation[0] : ''
                "
                >{{ choice.candidateName
                }}<span class="vote-count">{{
                  votePercentage && contest.value.totalVotes > 0
                    ? ((choice.votes / contest.value.totalVotes) * 100
                        | number: '1.1-1') + '%'
                    : choice.votes
                }}</span>
                <div
                  class="fill-bar"
                  [ngStyle]="{
                    width: contest.value.totalVotes
                      ? (choice.votes / contest.value.totalVotes) * 100 + '%'
                      : '0%'
                  }"
                ></div>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
